import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import en from './locales/en-EN';
import de from './locales/de-DE';

const interval = 20 * 1000;
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const getOrders = async ({ token }) => {
  const response = await fetch(
    `${apiUrl}/ticket_platform/orders/pickup-index/${token}?limit=8`,
    {
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': token,
      },
    }
  );
  const data = await response.json();

  return data;
};

const locales = {
  'en-US': en,
  'de-DE': de,
};

const PickupScreen = ({ lang }) => {
  const { token } = useParams();

  const [orders, setOrders] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (token) {
      setLoading(true);
      getOrders({ token })
        .then((data) => {
          setOrders(data);
        })
        .catch((err) => setError(err))
        .finally(() => setLoading(false));
    }

    const intervalId = setInterval(() => {
      if (token) {
        getOrders({ token })
          .then((data) => {
            setOrders(data);
          })
          .catch((err) => setError(err))
          .finally(() => setLoading(false));
      }
    }, interval);
    return () => clearInterval(intervalId);
  }, [token]);

  if (isLoading) {
    return (
      <p
        style={{
          color: '#fff',
          fontSize: 32,
          padding: 16,
          textAlign: 'center',
          marginBottom: 0,
        }}
      >
        {locales[lang]?.loading?.title || 'Loading orders...'}
      </p>
    );
  }

  if (error) {
    return (
      <div>
        <p
          style={{
            color: '#fff',
            fontSize: 32,
            padding: 16,
            textAlign: 'center',
            marginBottom: 0,
          }}
        >
          {locales[lang]?.auth?.error?.title || 'Authorization error'}
        </p>
        <p
          style={{
            color: '#fff',
            fontSize: 18,
            padding: 16,
            textAlign: 'center',
            marginTop: 0,
          }}
        >
          {locales[lang]?.auth?.error?.subtitle || 'No orders to pickup'}
        </p>
      </div>
    );
  }

  if (!orders.length) {
    return (
      <p
        style={{
          color: '#fff',
          fontSize: 24,
          padding: 16,
          textAlign: 'center',
        }}
      >
        {locales[lang]?.orders?.noOrders?.title || 'No orders to pickup'}
      </p>
    );
  }

  return (
    <div
      style={{
        boxSizing: 'border-box',
        padding: 30,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        backgroundColor: '#458316',
      }}
    >
      {orders?.map((number) => (
        <span
          key={number.id}
          style={{
            height: 240,
            width: 240,

            backgroundColor: '#fefefe',
            fontSize: 100,
            fontWeight: 600,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '1%',
          }}
        >
          <span style={{ color: '#605f61' }}>{number?.display_number}</span>
        </span>
      ))}
    </div>
  );
};

export default PickupScreen;
