const de = {
  auth: {
    error: {
      title: 'Autorisierungsfehler',
      subtitle: 'Daten konnten nicht abgerufen werden',
    },
  },
  orders: {
    noOrders: {
      title: 'Keine Bestellungen abholbereit',
    },
  },
  loading: {
    title: 'Lade Bestellungen...',
  },
};

export default de;
