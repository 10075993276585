const en = {
  auth: {
    error: {
      title: 'Authorization error',
      subtitle: 'Could not get the data',
    },
  },
  orders: {
    noOrders: {
      title: 'No orders to pickup',
    },
  },
  loading: {
    title: 'Loading orders...',
  },
};

export default en;
