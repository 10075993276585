import { useState } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import PickupScreen from './PickupScreen';

function App() {
  const [lang] = useState(window.navigator.language || 'en-US');

  return (
    <Router>
      <Routes>
        <Route path="/:token" element={<PickupScreen lang={lang} />} />
        <Route
          path="/"
          element={
            <div
              style={{
                boxSizing: 'border-box',
                padding: 30,
                display: 'flex',
                justifyContent: 'center',
                color: '#fff',
                fontSize: 24,
                backgroundColor: '#458316',
              }}
            >
              Main screen
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
